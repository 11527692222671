import { useEffect, useState } from "react";
import { ASSETS_URL } from "../../assets/constants/assetsUrl";

type Props = {
  className?: string;
  alt?: string;
  mockImg?: string;
  src?: string;
  width?: string | number;
  height?: string | number;
  priority?: boolean;
  onClick?: () => void;
};

const CustomImage = ({
 src, width, height, mockImg, alt, className, priority, onClick,
}: Props) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    error && setError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);
  return (
    <div onClick={onClick} className={className}>
      <img
        title={alt}
        className={className}
        height={height}
        width={width}
        loading={priority ? "eager" : "lazy"}
        onError={mockImg ? () => setError(true) : undefined}
        alt={alt}
        src={(error && mockImg) ? mockImg : src ? `${ASSETS_URL}${src?.[0] === "/" ? src.slice(1, src.length) : src}` : mockImg}
      />
    </div>
  );
};

export default CustomImage;
